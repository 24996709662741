<template>
  <div class="view-login mb-5">
    <div v-if="!success">
      <p class="mb-2">Criar nova senha</p>

      <form-group
        v-model="form.password"
        id="password"
        label="Digite nova senha"
        icon="fa-regular fa-key"
        type="password"
        placeholder="Digite a nova senha"
        :errors="errors.password"
        autoCheck
        @setError="setError"
      />

      <form-group
        v-model="form.confirm_password"
        id="confirm_password"
        label="Confirmar nova senha"
        icon="fa-regular fa-key"
        type="password"
        placeholder="Digite a senha novamente"
        :errors="errors.confirm_password"
        autoCheck
        @setError="setError"
      />
      <auth-button
        text="Salvar"
        class="mt-4 pt-3"
        :loading="loading"
        :disabled="loading"
        @click="submit"
      />
      <p class="text-center text-white mt-4">
        <router-link class="main" :to="{ name: 'login' }">
          Voltar para login
        </router-link>
      </p>
    </div>
    <div v-else class="text-center">
      <div class="d-flex justify-content-center">
        <lottie-vue-player
          :src="check"
          class="mr-4"
          :speed="0.8"
          autoplay
          :player-controls="false"
          style="width: 136px; background: transparent;"
        />
      </div>
      <div class="text-center mb-2">
        Concluído
      </div>
      <div>
        Faça login com sua nova senha
      </div>
      <auth-button text="Fazer login" class="mt-5" @click="login" />
    </div>
  </div>
</template>

<script>
import AuthButton from "@/components/auth/AuthButton.vue";
import { formFields } from "../functions/helpers";

export default {
  components: { AuthButton },
  name: "login",
  data() {
    return {
      ...formFields(["password", "confirm_password"]),
      loading: false,
      success: false
    };
  },
  computed: {
    check() {
      return JSON.stringify(require("@/assets/auth/check.json"));
    }
  },
  methods: {
    setError(error, field) {
      this.errors[field] = error;
    },
    login() {
      this.$router.push({ name: "login" });
    },
    submit() {
      this.$message.hide();

      if (this.form.password !== this.form.confirm_password) {
        if (!this.errors.confirm_password[0])
          this.errors.confirm_password = ["Senhas não conferem"];
        if (!this.errors.password[0])
          this.errors.password = ["Senhas não conferem"];
        return;
      }

      this.loading = true;

      this.$store
        .dispatch("user/resetPassword", {
          password: this.form.password,
          token: this.$route.query.token
        })
        .then(() => {
          this.success = true;
        })
        .catch(error => {
          if (!this.errors.password[0])
            this.errors.password = error.response.data.password;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-login {
  width: 100%;
  color: var(--std-neut-x-700, #d5d5d5);
  font-weight: 400 !important;
  hr {
    width: 25%;
    border-top: 2px solid var(--semantic-neutral-700, #d5d5d5);
  }
  a {
    font-size: 16px;
  }
  .footer {
    text-align: center;
    font-size: 16px;
    color: var(--semantic-neutral-600, #c7c7c7);
    a {
      color: #fff;
    }
  }
}
</style>
